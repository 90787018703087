@tailwind components;
@tailwind utilities;

.earlyAdopter {
    background-color: rgb(0, 60, 255);
    top: 100px;
    border-radius: 10px;
  }

.tos h1{ font-size:medium; margin-bottom: 20px;}
.tos h2{ font-size:medium; margin-bottom: 0px;}


.custom-modal .modal-content {
   background: linear-gradient(to top, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0)),
              url('/Assets/img/teen-girl-in-coffee-shop-banner.jpg');
 // background-size: cover; /* Adjust to ensure the image covers the modal */
  background-position: center -30px; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating */
  border-radius: 8px; /* Optional: Rounded corners */
  padding-top: 150px;
}

.custom-modal .modal-content .modal-title{color:#000 !important; margin-bottom: -20px;}